<template>
  <a-drawer width="40%" :label-col="4" :wrapper-col="14" :visible="open" :title="formTitle" @close="onClose">
    <a-form-model ref="form" :model="form" :rules="rules" class="area-manager">
      <a-form-model-item label="路段名称" prop="name">
        <a-input v-model="form.name" :disabled="readOnly" placeholder="请输入路段名称" />
      </a-form-model-item>
      <a-form-model-item label="路段类型" prop="markType">
        <div class="tip-icon">
          <a-popover title="提示" trigger="hover">
            <template slot="content">
              <div>工作区：工作区围栏，作用于在岗情况</div>
              <div>停车场：停车区域围栏，作用于车辆停车</div>
              <div>禁行区：禁行区域围栏，车辆不能行驶的区域</div>
              <div>考勤区：考勤区域围栏，作用于小程序上下班打卡区域</div>
            </template>
            <img :src="require('@/assets/images/tip.png')" alt="" />
          </a-popover>
        </div>
        <a-select
          placeholder="请选择路段类型"
          v-model="form.markType"
          class="marktype-wrap"
          style="width: 100%"
          allow-clear
        >
          <a-select-option v-for="(d, index) in markTypeOptions" :key="index" :value="d.dictValue">{{
            d.dictLabel
          }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="所属部门" prop="deptId">
        <a-tree-select
          :disabled="readOnly"
          v-model="form.deptId"
          style="width: 100%"
          :replaceFields="replaceFields"
          :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
          :tree-data="deptOptions"
          placeholder="请选择"
          tree-default-expand-all
        >
        </a-tree-select>
      </a-form-model-item>
      <a-form-model-item label="路段范围" prop="geomText">
        <a-button @click="handleMapDraw">在地图中绘制</a-button>
      </a-form-model-item>
      <!-- <a-form-model-item label="区域颜色">
        <div v-if="!isOpenColor" @click="isOpenColor = true" class="area-color" :style="'background:' + form.color">&nbsp;</div>
        <div v-else>
          <photoshop-picker v-model="colors" @ok="handleColorOk" @cancel="handleColorCancel"></photoshop-picker>
        </div>
      </a-form-model-item> -->

      <a-form-model-item label="几何类型">
        <span>{{ geomTypeObj[form.geomType] }} </span>
      </a-form-model-item>
      <!-- <a-form-model-item label="限制出入">
        <a-radio-group v-model="form.limitInOut" button-style="solid">
          <a-radio-button :value="0">无</a-radio-button>
          <a-radio-button :value="1">进区域</a-radio-button>
          <a-radio-button :value="2">出区域</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="限速"> <a-input-number v-model="form.limitSpeed" /> <span class="input-remark">(公里每小时 0不限制)</span> </a-form-model-item>
      <a-form-model-item label="	限停" class="label-row"> <a-input-number v-model="form.limitTime" /><span class="input-remark">(分钟 0不限制)</span> </a-form-model-item> -->
      <!-- <a-form-model-item label="标记类型">
        <a-radio-group v-model="form.markType" button-style="solid">
          <a-radio-button :value="1">作业区</a-radio-button>
          <a-radio-button :value="2">停车场</a-radio-button>
          <a-radio-button :value="3">禁行区</a-radio-button>
        </a-radio-group>
      </a-form-model-item> -->
      <a-form-model-item label="开始日期">
        <a-date-picker :disabled="readOnly" v-model="form.startDate" value-format="YYYY-MM-DD" format="YYYY-MM-DD" />
      </a-form-model-item>
      <a-form-model-item label="开始时间">
        <a-time-picker :disabled="readOnly" v-model="form.startTime" value-format="HH:mm:ss" format="HH:mm:ss" />
      </a-form-model-item>
      <a-form-model-item label="结束日期">
        <a-date-picker :disabled="readOnly" v-model="form.endDate" value-format="YYYY-MM-DD" format="YYYY-MM-DD" />
      </a-form-model-item>
      <a-form-model-item label="结束时间">
        <a-time-picker :disabled="readOnly" v-model="form.endTime" value-format="HH:mm:ss" format="HH:mm:ss" />
      </a-form-model-item>
      <a-form-model-item label="生效日" v-if="form.markType === 1">
        <div>
          <a-checkbox
            :disabled="readOnly"
            :indeterminate="indeterminate"
            :checked="checkAll"
            @change="handleWeekCheckAllChange"
          >
            全选
          </a-checkbox>
        </div>
        <div>
          <a-checkbox-group
            :disabled="readOnly"
            v-model="checkedList"
            :options="weekOptions"
            @change="handleWeekChange"
          />
        </div>
      </a-form-model-item>
      <div class="check-config" v-if="form.markType === 1">
        <div class="row">
          <span class="col header">抽检时段</span>
          <span class="col header">应在岗人数</span>
          <span class="col header">时段内抽检次数</span>
          <span class="col header">最小行走里程</span>
          <span class="delete-btn header"></span>
        </div>
        <div class="row" v-for="(item, index) in samplingRules" :key="index">
          <span class="col">
            <a-time-picker
              :disabled="readOnly"
              :width="80"
              v-model="item.startTime"
              value-format="HH:mm:ss"
              format="HH:mm:ss"
              @change="handeStartTimeChange"
            />
            <span class="connect-flag">-</span>
            <a-time-picker
              :disabled="readOnly"
              :width="80"
              v-model="item.endTime"
              value-format="HH:mm:ss"
              format="HH:mm:ss"
              @change="handEndTimeChange"
            />
          </span>
          <span class="col"> <a-input-number :disabled="readOnly" v-model="item.mustOnDutyCount" :min="0" /></span>
          <span class="col"> <a-input-number :disabled="readOnly" v-model="item.samplingCount" :min="0" /></span>
          <span class="col"> <a-input-number :disabled="readOnly" v-model="item.minWalkMileage" :min="0" /></span>
          <span class="delete-btn" v-if="!readOnly">
            <a-icon
              type="delete"
              class="item-delete"
              @click="handleDelete(index)"
            /></span>
        </div>
      </div>
      <div class="add-btn" v-if="form.markType === 1">
        <a-button
          :disabled="readOnly"
          @click="handleAddCheckTime"
          type="primary"
          class="add"
        ><a-icon
          type="plus"
        /></a-button>
      </div>
      <div class="bottom-control">
        <a-space>
          <a-button v-if="!readOnly" type="primary" :loading="loading" @click="submitForm"> 保存 </a-button>
          <a-button type="dashed" @click="cancel"> {{ readOnly ? '返回' : '取消' }} </a-button>
        </a-space>
      </div>
    </a-form-model>
    <road-scope-map ref="roadScopeMapRef" :position="{ top: '4%', left: '52%' }" @ok="handleOk" />
  </a-drawer>
</template>

<script>
import { addAreaManager, updateAreaManager, getAreaManager } from '@/api/jt808/areaManager'
import { delSamplingRule } from '@/api/jt808/samplingRule'
import { TreeSelect, Popover } from 'ant-design-vue'
import { treeselect } from '@/api/system/dept'
import RoadScopeMap from './RoadScopeMap'
import XEUtils from 'xe-utils'
// import { Photoshop } from 'vue-color'

export default {
  name: 'CreateForm',
  props: {},
  components: {
    ATreeSelect: TreeSelect,
    APopover: Popover,
    RoadScopeMap
    // 'photoshop-picker': Photoshop
  },
  data() {
    return {
      replaceFields: { children: 'children', title: 'label', key: 'id', value: 'id' },
      readOnly: false,
      loading: false,
      formTitle: '',
      // 表单参数
      form: {},
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        name: [{ required: true, message: '路段名称不能为空', trigger: 'blur' }],
        geomText: [{ required: true, message: '路段范围不能为空', trigger: 'change' }],
        deptId: [{ required: true, message: '所属部门不能为空', trigger: 'change' }]
      },
      deptOptions: [],
      markTypeOptions: [
        { dictValue: 1, dictLabel: '工作区' },
        { dictValue: 2, dictLabel: '停车场' },
        { dictValue: 3, dictLabel: '禁行区' },
        { dictValue: 4, dictLabel: '考勤区' }
      ],
      roadScopeMapVisible: false,
      indeterminate: true,
      checkAll: false,
      checkedList: [],
      weekOptions: [
        { label: '周一', value: 1 },
        { label: '周二', value: 2 },
        { label: '周三', value: 3 },
        { label: '周四', value: 4 },
        { label: '周五', value: 5 },
        { label: '周六', value: 6 },
        { label: '周日', value: 0 }
      ],
      geomTypeObj: {
        1: '圆形',
        2: '矩形',
        3: '多边形',
        4: '路线'
      },
      colors: {},
      isOpenColor: false,
      samplingRules: []
    }
  },
  filters: {},
  created() {
    this.getTreeselect()
  },
  computed: {},
  watch: {
    open(val) {}
  },
  mounted() {
    this.handleAddCheckTime()
  },
  methods: {
    handleDelete(index) {
      this.$confirm({
        title: '确认要删除这条记录吗?',
        content: '',
        zIndex: 99999,
        onOk: () => {
          const record = this.samplingRules[index]
          console.log('delete')
          if (record.id) {
            delSamplingRule(record.id).then((response) => {
              this.samplingRules.splice(index, 1)
            })
          } else {
            this.samplingRules.splice(index, 1)
          }
        },
        onCancel() {}
      })
    },
    handleAddCheckTime() {
      this.samplingRules.push({
        startTime: '',
        endTime: '',
        minWalkMileage: 0.01,
        mustOnDutyCount: 0,
        samplingCount: 0
      })
    },
    handleColorOk() {
      this.form.color = this.colors.hex
      this.isOpenColor = false
    },
    handleColorCancel(val) {
      this.isOpenColor = false
    },
    handleOk(obj) {
      console.log('obj', obj)
      this.form.geomType = obj.geomType
      this.form.geomText = obj.geomText
      this.$refs.form.clearValidate('geomText')
    },
    /** 查询部门下拉树结构 */
    getTreeselect() {
      const query = {}
      treeselect(query).then((response) => {
        this.deptOptions = response.data
      })
    },
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.loading = false
      this.formType = 1
      this.checkedList = []
      this.samplingRules = []
      this.form = {
        areaDesc: '',
        deptId: '',
        endDate: '',
        endTime: '',
        geomText: '',
        geomType: 0,
        limitInOut: 0,
        limitSpeed: 0,
        limitTime: 0,
        markType: 1,
        name: '',
        startDate: '',
        startTime: '',
        stateFlag: 0,
        weeks: 0,
        color: '#333'
      }
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.handleAddCheckTime()
      this.formType = 1
      this.readOnly = false
      this.open = true
      this.formTitle = '添加'
      this.checkedList = this.weekOptions.map((p) => p.value)
      this.indeterminate = false
      this.checkAll = true
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids, type) {
      console.log('handleUpdate')
      this.readOnly = type === 'read'
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getAreaManager(id).then((response) => {
        this.form = response.data
        this.samplingRules = response.rules
        this.getCheckListByValue()
        this.open = true
        this.formTitle = this.readOnly ? '查看路段' : '修改'
      })
    },
    // 生效日计算
    getWeeksValue() {
      let weekValue = 0
      this.checkedList.forEach((p) => {
        weekValue += Math.pow(2, p)
      })
      return weekValue
    },
    // 基于值转为数值列表
    getCheckListByValue() {
      if (this.form.weeks > 0) {
        console.log('getCheckListByValue')
        const bitStr = this.form.weeks.toString(2)
        const weekArray = bitStr.split('')
        // 倒序
        weekArray.reverse()
        weekArray.forEach((p, idx) => {
          if (p === '1') {
            this.checkedList.push(idx)
          }
        })

        this.indeterminate = !!this.checkedList.length && this.checkedList.length < this.weekOptions.length
        this.checkAll = this.checkedList.length === this.weekOptions.length
      }
    },
    // 校验
    doVerifyBeforeSave() {
      const n = this.samplingRules.length
      let result = true
      if (n > 0) {
        for (let k = 0; k < n; k++) {
          if (!this.samplingRules[k].startTime) {
            result = false
            this.$warningEx('抽检时段开始时间不能为空！')
            break
          } else if (!this.samplingRules[k].endTime) {
            result = false
            this.$warningEx('抽检时段结束时间不能为空！')
            break
          }
        }
      }
      return result
    },
    /** 提交按钮 */
    submitForm: function () {
      if (!this.checkDate() || (this.form.markType === 1 && !this.doVerifyBeforeSave())) {
        return
      }
      this.form.weeks = this.getWeeksValue()
      if (this.form.markType === 1) {
        this.form.samplingRules = this.samplingRules
      } else {
        this.form.samplingRules = undefined
      }
      console.log('weeks', this.form.weeks)
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateAreaManager(this.form).then((response) => {
              this.loading = false
              this.$message.success('修改成功', 3)
              this.open = false
              this.$emit('ok')
            })
          } else {
            addAreaManager(this.form).then((response) => {
              this.loading = false
              this.$message.success('新增成功', 3)
              this.open = false
              this.$emit('ok')
            })
          }
        } else {
          return false
        }
      })
    },
    handleMapDraw() {
      this.$refs.roadScopeMapRef.drawMap(this.form, false, this.readOnly)
    },
    handleWeekChange(checkedList) {
      console.log('handleWeekChange')
      this.indeterminate = !!checkedList.length && checkedList.length < this.weekOptions.length
      this.checkAll = checkedList.length === this.weekOptions.length
    },
    handleWeekCheckAllChange(e) {
      this.checkedList = e.target.checked ? this.weekOptions.map((p) => p.value) : []
      this.indeterminate = false
      this.checkAll = e.target.checked
    },
    checkDate() {
      if (this.form.startDate && this.form.endDate) {
        let startDateTime = ''
        let endDateTime = ''
        startDateTime = this.form.startDate
        endDateTime = this.form.endDate
        if (this.form.startTime) {
          startDateTime = startDateTime + ' ' + this.form.startTime
        }
        if (this.form.endTime) {
          endDateTime = endDateTime + ' ' + this.form.endTime
        }
        if (startDateTime === endDateTime) {
          return true
        }
        const startDate = XEUtils.toStringDate(startDateTime)
        const endDate = XEUtils.toStringDate(endDateTime)
        const res = XEUtils.getDateDiff(startDate, endDate, [])
        if (!res.done) {
          this.$warning({
            title: 'Warning',
            content: '开始日期时间不能大于结束日期时间！'
          })
          return false
        }
      }
      return true
    }
  }
}
</script>

<style lang="less">
.area-manager {
  .ant-form-item {
    display: flex;
    align-items: center;
    .ant-form-item-label {
      flex: 0 0 80px;
    }
    .ant-form-item-control-wrapper {
      flex: 1;
    }
  }
  .input-remark {
    margin-left: 10px;
  }
  .area-color {
    width: 25px;
    height: 25px;
    border: 1px solid black;
  }
  .bottom-control {
    border-top: 0;
    padding-top: 30px;
  }
}

.check-config {
  .row {
    display: flex;
    .item-delete {
      font-size: 20px;
    }
    .col {
      flex: 1;
      text-align: center;
      border-top: 1px solid #e8e8e8;
      border-left: 1px solid #e8e8e8;
    }
    .header {
      height: 30px;
      line-height: 30px;
      padding: 0 2px;
    }
    .col:first-child {
      flex: 1;
      min-width: 280px;
    }
    .delete-btn {
      flex: 0 0 30px;
      border: 0;
      border-left: 1px solid #e8e8e8;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .add {
      width: 100%;
    }
    .ant-input-number {
      border-radius: 0;
      width: 100%;
      border: 0;
    }
    input {
      border-radius: 0;
      text-align: center;
    }
    .ant-time-picker {
      border: 0;
    }
    .ant-time-picker-input {
      border: 0 !important;
    }
    .connect-flag {
      border: 0;
      width: 5px;
    }
  }
  .row:last-child {
    .col {
      border-bottom: 1px solid #e8e8e8;
    }
  }
}
.add-btn {
  width: 100%;
  margin-top: 20px;
  margin-right: 30px;
  button {
    width: 100%;
  }
}

.tip-icon {
  position: absolute;
  left: 4px;
  width: 14px;
  height: 10px;
  z-index: 9;
  height: 100%;
  top: 1px;
  display: flex;
  align-items: center;
}

.tip-icon img {
  width: 100%;
}
</style>
<style>
.marktype-wrap .ant-select-selection--single .ant-select-selection__rendered {
  padding-left: 10px;
}
</style>
